<ul class="player-list">
    @if(users && users?.length > 0) {
    @for (user of users; track $index) {
    <app-g2g-user-tile [user]="user"></app-g2g-user-tile>
    }
    } @else {
    <div class="no-players w-100 d-flex align-items-center justify-content-center">
        No Data Available
    </div>
    }
</ul>

@if(users && users?.length) {
<p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalCount" />
}